<template>
    <div class="com-introduction-page wid1200">
        <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        <div class="com-introduction">
            <div class="left-com-introduction">
                <h5>
                    <img v-if="$route.query.id=='yyb_gyyyb'" src="../assets/img/preprinticon.png" alt=""
                        style="width: 30px; height: 31px;" />
                    <img v-else-if="$route.query.id=='kfhq_gykfhq'" src="../assets/img/oaIntro.png" alt=""
                        style="width: 29px; height: 15px;" />
                    <img v-else src="../assets/img/standard.png" alt=""
                        style="width: 24px; height: 24px;vertical-align: sub;" />

                    关于{{$route.query.id=='yyb_gyyyb'? '预印本' : $route.query.id=='kfhq_gykfhq' ? '开放获取' : '集刊规范'}}
                </h5>
                <!-- mediaPath -->
                <ul>
                    <li v-for="item in instroData" :key="item.id" :class="[activeTitle==item.title ? 'active': '']"
                        @click="switchTitle(item.title)"> <a :title="item.title">{{item.title}}</a></li>
                </ul>
            </div>
            <div class="right-com-introduction">
                <div class="content-intro" v-for="item in instroData" :key="item.id" v-if="activeTitle==item.title">
                    <p v-if="item.content" v-html="item.content"></p>

                    <div v-else class="showPdf">
                        <!-- <iframe style="width: 100%; height: 1000px; box-shadow: -6px 4px 8px #ccc;" :src="item.mediaPath" frameborder="0" ></iframe> -->

                        <iframe style="width: 100%; height: 1000px;" :src="'/pdf/web/viewer.html?file='+item.mediaPath"
                            width="100%" height="1000" frameborder="0">
                            <a :href="'/pdf/web/viewer.html?file='+item.mediaPath">你的浏览器不支持iframe页面嵌套，请点击这里访问页面内容。</a>
                        </iframe>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
    components: { BreadCrumb },
    data() {
        return {
            pN: '',
            pageName: '开放获取简介', //开放获取简介、预印本简介
            instroData: [],
            activeTitle: ''
        }
    },
    watch: {
        $route: 'init'
    },
    methods: {
        init() {
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }

            if (this.$route.query.id == 'yyb_gyyyb') {
                this.pageName = '关于预印本'
                // if (this.$route.query.title) {
                //     this.activeTitle = this.$route.query.title
                // }
            } else if (this.$route.query.id == 'kfhq_gykfhq') {
                this.pageName = '关于开放获取'
            } else if (this.$route.query.id == 'jkgf_gyjkgf') {
                this.pageName = '集刊规范'
            }
            // console.log('this.$route.query.id', this.$route.query.id)
            this.getData(this.$route.query.id)
        },
        getData(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list',
                params: {
                    innerCode: id,
                    pageSize: 100,
                    pageNum: 1
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.instroData = res.data.data.rows
                    if (_this.$route.query.titleId) {
                        _this.instroData.forEach(e => {
                            if (e.id == _this.$route.query.titleId) {
                                _this.activeTitle = e.title
                            }
                        })
                    } else {
                        _this.activeTitle = _this.instroData[0].title
                    }
                }
            })
        },
        switchTitle(title) {
            this.activeTitle = title
        }
    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
.com-introduction-page {
    .com-introduction {
        display: flex;
        justify-content: space-between;
        padding: 27px 30px;
        min-height: 500px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        .left-com-introduction {
            width: 320px;
            h5 {
                width: 100%;
                height: 58px;
                line-height: 58px;
                background: #cb2f3c;
                color: #fff;
                font-size: 20px;
                padding: 0 24px;
                box-sizing: border-box;
                img {
                    width: 29px;
                    height: 15px;
                    vertical-align: middle;
                }
            }
            ul {
                li {
                    width: 100%;
                    height: 62px;
                    line-height: 62px;
                    border: 1px solid #e6e6e6;
                    border-top: none;
                    font-size: 18px;
                    color: #666;
                    padding: 0 24px;
                    box-sizing: border-box;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    a {
                        display: block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .active {
                    background: #f7f7f7;
                    color: #bd1a2d;
                }
            }
        }
        .right-com-introduction {
            .content-intro {
                width: 796px;
            }
        }
    }
    .showPdf {
        position: relative;
        border: 2px solid #3c3c3c;
        background: #3c3c3c;
        box-shadow: -6px 4px 8px #ccc;
    }
    .showPdf .down {
        position: absolute;
        top: 9px;
        right: 130px;
    }
    .showPdf img {
        width: 15px;
    }
}
</style>